var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sideBar",attrs:{"id":"form-sidebar","visible":_vm.isActive,"backdrop":"","no-close-on-esc":true,"no-close-on-backdrop":true,"bg-variant":"white","title":"","right":"","shadow":"","sidebar-class":"sidebar-lg","header-class":"content-sidebar-header","footer-class":"content-sidebar-footer"},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Cadastro de Tags ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer float-right",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]}},{key:"footer",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"p-1"},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"sm":"12","md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"button"},on:{"click":_vm.onSubmit}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_vm._v(" Salvar ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-danger","type":"button"},on:{"click":_vm.onCancel}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"prefix":"far","icon":['far', 'times-circle']}}),_vm._v(" Cancelar ")],1)],1)],1)],1)]}},{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12"}},[_c('i2-form-input',{attrs:{"rules":"required","label":"Nome","placeholder":"","name":"name"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"12"}},[_c('i2-form-input',{attrs:{"rules":"required","label":"Contexto","placeholder":"","name":"context"},model:{value:(_vm.model.context),callback:function ($$v) {_vm.$set(_vm.model, "context", $$v)},expression:"model.context"}})],1)],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }